/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.FormVisit .box {
  border: 1px solid rgba(221, 221, 221, 0.493);
  padding: 40px 20px;
  min-height: 500px;
  margin-bottom: 20px;
}
.FormVisit label {
  color: #6c6c6c;
}
@media (min-width: 1200px) {
  .FormPatient {
    max-height: 69vh;
  }
}
@media (min-width: 1600px) {
  .FormPatient {
    max-height: 75vh;
  }
  .FormPatient-create {
    max-height: 74vh;
  }
}
