/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.Doctor-data-import {
  font-family: "Sukhumvit Set";
  font: normal normal medium 18px/24px Roboto;
  color: #ff748e;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.data-webkitline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.Doctor-status-content {
  display: flex;
  align-items: center;
}
.Doctor-status-point01 {
  background-color: #ff748e;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.Doctor-status-point02 {
  background-color: #575a89;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.FormDoctor {
  height: 100%;
  max-height: 67vh;
  overflow: hidden;
  overflow-y: auto;
}
.FormDoctor .box {
  border: 1px solid rgba(221, 221, 221, 0.493);
  padding: 40px 20px;
  min-height: 350px;
  margin-bottom: 20px;
}
.FormDoctor::-webkit-scrollbar {
  display: none;
}
