@media print {
  body,
  html {
    min-width: 100vw;
    width: 21cm !important;
    // height: 29.7cm !important;
    height: 100% !important;

    margin: 0;
  }
  .print-preview {
    // width: 100vw;
    width: 21cm;
    // height: 29.7cm;
    height: 100%;
    margin: 0;
    border: 5px solid #000;
  }
}

.contrainer-formprint {
  // padding: 0ox 8px 7rem 10px;
  min-height: 100%;
  height: 100%;
  background-color: white;
}

.logo-print {
  // height: 65px;
  width: 150px;
  padding: 5px 5px;
  max-height: 80px;
  // border: solid red 1px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: @primary-color;
  margin-left: -25px;
}

.image-logoprint {
  height: 100%;
}

.site-layout-menu-background {
  // -moz-box-shadow: 2px 2px 15px -8px rgba(189, 189, 189);
  // -webkit-box-shadow: 2px 2px 15px -8px rgba(189, 189, 189);
  // background-color: #fff;
  background-image: url("../../../assets/images/tree.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 45px;
  background-position-x: 96%;
  height: 100px;
  // padding-right: 10px;
  // box-shadow: 2px 2px 15px -8px rgb(189, 189, 189);
}

///////////Form_Print///////////////////
.print-logo {
  padding: 20px 0px 20px 10px;
  width: 250px;
}

.print-bgNav {
  padding: 20px 0px 20px 40px;
  margin-left: -10px;
  width: 110px;
}

.font-bold {
  font-weight: bold;
}

.label-width {
  width: 190px;
}

.contrainer-qr {
  border-right: solid #f7f7f7 1px;
  display: flex;
  justify-content: center;
  padding: 22px;
}

.contrainer-pass {
  font-size: 24pt;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50%;
}

.row-pass {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  height: 100%;
}

.col-pass {
  background-color: white;
  width: 50px;
  height: 50px;
}

.row-label {
  background-color: #f7f7f7;
  width: "100%";
  // font-size: 1pt;
}

.span-label {
  font-size: 6pt;
}

.test-printlayout {
  height: 20px !important;
}

.content-container {
  padding: 8px;
}

.info-section {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.info-container {
  padding: 8px;
}

.info-header {
  background-color: #e5f1fb;
  padding: 8px;
  font-weight: bold;
  margin-bottom: 10px;
}

.info-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.info-label {
  flex: 0 0 150px;
  text-align: right;
}

.info-value {
  padding-left: 60px;
  flex: 1;
  text-align: left;
  word-wrap: break-word;
  line-height: 1.5;
}

.content-row {
  display: flex;
  align-items: center;
}
.container-footer {
  background-color: #1a73e8;
  padding: 8px;
  color: white;

  .qr-code {
    width: 100px;
    height: 100px;
  }
}
@page {
  size: A4;
  margin: 18mm;
}
