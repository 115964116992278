/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.iconHead {
  display: flex;
  justify-content: flex-end;
}
.Card-Diagnose .ant-card-body {
  background-color: rgba(255, 255, 255, 0.1);
  font: normal normal bold 46px/80px SF Pro;
  color: #1C5AE8;
  font-family: "Sukhumvit Set";
  padding: 0px 10px;
}
.avatar-boder .ant-avatar {
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid;
}
.font-size {
  font: normal normal 300 18px/43px Sukhumvit Set;
  font-family: "Sukhumvit Set";
  opacity: 0.5;
  color: #101010;
}
.font-Quantity span {
  font: normal normal normal 20px/40px Sukhumvit Set;
  font-family: "Sukhumvit Set";
}
.Text-Patients {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
}
.Box-text-Patients {
  font: normal normal bold 20px/43px SF Pro;
  font-family: "Sukhumvit Set";
}
.Font-years {
  font: normal normal medium 16px/26px Sukhumvit Set;
  font-family: "Sukhumvit Set";
  color: #7e84a3;
}
.Font-years .ant-select-arrow {
  top: 29%;
}
.flex-box {
  display: flex;
  flex-direction: row;
}
.flex-quantity {
  display: block;
}
.Fornt-Card {
  font: normal normal normal 20px/40px Sukhumvit Set;
}
.Textbox-number {
  font: normal normal bold 46px/80px SF Pro;
  color: #1C5AE8;
  font-family: "Sukhumvit Set";
  height: 40px;
}
.Textbox-number span {
  font: normal normal 300 18px/43px Sukhumvit Set;
  font-family: "Sukhumvit Set";
  color: #101010;
}
.flex-img-quantity {
  display: block;
  margin-left: auto;
}
.flex-img-quantity img {
  object-fit: cover;
  object-position: center;
}
.flex-box-risk {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 65px;
}
.flex-box-risk-level {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 30px;
}
.classssss {
  border-left: solid 1px #e6e9f4;
  border-right: solid 1px #e6e9f4;
}
.avatar-boder-risk {
  background-color: #ffffff;
  border: 3px #475f7b solid;
}
.Box-body-level {
  font: normal normal bold 16px/40px Sukhumvit Set;
  margin-left: 25px;
}
.Box-body-level-font {
  font-family: "Sukhumvit Set";
  font: normal normal bold 30px/55px SF Pro;
}
.Font-risk {
  font: normal normal normal 20px/40px Sukhumvit Set;
  height: 45px;
}
.avatar-boder-risk-1 {
  background-color: #ffffff;
  border: solid 3px #1C5AE8;
}
.Number-risk {
  font: normal normal bold 30px/50px Sukhumvit Set;
  font-family: "Sukhumvit Set";
}
.Font-risk-level {
  font: normal normal bold 16px/40px Sukhumvit Set;
}
.flex-boder {
  box-sizing: border-box;
}
.box-boder-line {
  border-right: solid 1px #e6e9f4;
}
