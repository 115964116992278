@media (min-width: @screen-md-min) {
    .longdoMap-height{
        min-height: 100%;
    }
    .height-resultMap{
        height:450px;
       
    }
    .drawer-create-visit .ant-drawer-content-wrapper{
        width: 740px !important;
      }
}


