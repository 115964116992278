#ABUS-layout-custom-trigger {
  min-height: 100vh;

  .trigger {
    cursor: pointer;
    // padding: 0 24px;
    font-size: 18px;
    transition: color 0.3s;
    line-height: 64px;
  }

  .trigger:hover {
    color: @primary-color;
  }

  .logo {
    height: 80px;
    padding: 15px 30px;
    max-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .site-layout-menu-background {
    -moz-box-shadow: 2px 2px 15px -8px rgba(189, 189, 189);
    -webkit-box-shadow: 2px 2px 15px -8px rgba(189, 189, 189);
    background-color: #fff;
    background-image: url("../../../assets/images/bg-dashboard.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 280px;
    box-shadow: 2px 2px 15px -8px rgb(189, 189, 189);
  }

  .site-layout-menu {
    padding: 20px 30px;
    background: transparent;

    .ant-menu-item {
      margin: 5px 0px;
      color: @dark-gray-color;
      height: @height-lg;
      font-size: 1.1rem;
      background: rgba(255, 255, 255, 0.7);

      .ant-menu-item-icon {
        font-size: 1.5rem;
      }

      .ant-menu-item-icon+span {
        margin-left: 20px;
      }
    }

    .ant-menu-item-selected {
      color: #fff;
      background-color: @primary-color;
    }

    .ant-menu-item-active {
      color: #fff;
      background-color: @primary-color;
    }
  }

  .site-layout .site-layout-background {
    background: #fff;
  }

  .header {
    padding: 0 24px;
    height: 80px;
    max-height: 80px;

    .dropdown-profile {
      cursor: pointer;

      .profile {
        line-height: 20px;
        min-width: 130px;

        .role {
          color: @gray-color;
          font-size: 0.8rem;
        }
      }
    }
  }

  .ant-layout-header {
    line-height: 80px;
  }

  .site-layout .site-layout-content {
    // margin: 24px 16px;
    padding: 24px;
  }

  // ---- collapsed ----
  .ant-layout-sider-collapsed {
    .logo {
      padding: 10px 15px;
    }

    .site-layout-menu {
      padding: 10px 15px;

      .ant-menu-item {
        height: 49px;
        padding: calc((49px - 1.5rem) / 2) calc((49px - 1.5rem) / 2);
      }
    }
  }
}