.form-print {
  position: fixed;
  width: 100%;
  height: 100% !important;
  z-index: -20;
}

.layout-form-print {
  width: 100%;
  //  padding: 6rem 5.8rem;
}

.form-print-label {
  min-width: 50%;
}

.form-print-label2 {
  min-width: 100%;
}

.list-status-low {
  background-color: #28c840;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.list-status-high {
  background-color: #ff748e;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.list-status-med {
  background-color: #febc2e;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.list-status-not {
  background-color: #646464;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.button-ai.ant-btn-lg {
  padding: 0px 15px !important;
}

.box-shadow-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
