.FormVisit {
  .box {
    border: 1px solid rgba(221, 221, 221, 0.493);
    padding: 40px 20px;
    min-height: 500px;
    margin-bottom: 20px;
  }
  label {
    color: #6c6c6c;
  }
}
