.Doctor-data-import {
  font-family: "Sukhumvit Set";
  font: normal normal medium 18px/24px Roboto;
  color: #ff748e;
  //font-size: 14pt;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.data-webkitline{
  // font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.Doctor-status-content {
  display: flex;
  align-items: center;
}
.Doctor-status-point01 {
  background-color: #ff748e;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.Doctor-status-point02 {
  background-color: #575a89;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.FormDoctor {
  height: 100%;
  max-height: 67vh;
  overflow: hidden;
  overflow-y: auto;
  .box {
    border: 1px solid rgba(221, 221, 221, 0.493);
    padding: 40px 20px;
    min-height: 350px;
    margin-bottom: 20px;
  }
}

.FormDoctor::-webkit-scrollbar {
  display: none;
}