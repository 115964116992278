@media (min-width: @screen-md-min) {
  .login-container {
    height: 100vh;
    padding: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../../assets//images/background-login.svg");
  }

  // .welcome-container {
  // }
  .welcome-subTitle {
    font-size: 1rem;
  }

  // .copyright {
  // }
  #components-form-normal-login {
    padding: 30px;
  }

  // #components-form-normal-login .login-form {
  // }
  // #components-form-normal-login .login-form-button {
  // }
  // #components-form-normal-login label {
  // }

  #components-form-normal-login .ant-form-item {
    margin-bottom: 32px;
  }
}