/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ImageUpload .ant-upload-select-picture-card,
.ImageUpload .ant-upload-list-picture-card {
  width: 150px;
  height: 150px;
}
@media (min-width: 1600px) {
  .ImageUpload .ant-upload-list-picture-card,
  .ImageUpload .ant-upload-select-picture-card,
  .ImageUpload .ant-upload-list-picture-card-container {
    width: 200px;
    height: 200px;
  }
  .ant-form-item-explain-connected {
    padding-top: 10px;
  }
}
