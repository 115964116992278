@media (min-width: @screen-lg-min) {
    .header-Collap{
        width: 96vw;
        // transition:  1s;
    }

    .header-NoCollap{
        width: 85.5vw;
        // transition:  1s;
    }
}
