/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.PagePaper {
  width: 100%;
  padding: 20px;
  min-height: 100%;
  padding-bottom: 0px;
  background-color: #ffff;
}
@media (min-width: 576px) {
  .test {
    color: #fff;
  }
}
@media (min-width: 1200px) {
  .test {
    color: #d63434;
  }
}
