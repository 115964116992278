.FormVisit {
  .box {
    border: 1px solid rgba(221, 221, 221, 0.493);
    padding: 40px 20px;
    min-height: 500px;
    margin-bottom: 20px;
  }
  label {
    color: #6c6c6c;
  }
}

.modalMap{
  background-color: "white";
  padding: "20px";
  height: "500px";
  margin-top: 20px;
}

.longdo-map{
  min-height: 100%;
  min-width: 100%;
}

.result-map{
  background-color: white;
  display: block;
  z-index: 10;
  flex-direction: column;
  padding: 4px;
  min-width: 100%;
  overflow-y: scroll;
  // overflow: hidden;
}

.result-map::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  // background-color: #aaa; /* or add it to the track */
}

.box-map {
  border: 1px solid rgba(221, 221, 221, 0.493);
  padding: 20px 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  min-height: 500px;
}