/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.FormUpload {
  overflow-y: auto;
  min-height: 100%;
}
.FormUpload .doctor-diagnose {
  padding: 20px;
}
.FormUpload .doctor-diagnose .container-box {
  background-color: #f1f5ff;
  border: 1px solid #abbfe9;
  margin: 0 !important;
}
.FormUpload .doctor-diagnose .tabs-content {
  padding: 20px 10px;
}
.FormUpload .doctor-diagnose .box {
  border-radius: 4px;
  padding: 30px 20px;
}
.FormUpload .doctor-diagnose .card {
  background-color: #fff;
  min-height: 200px;
  border-radius: 4px;
}
.FormUpload .doctor-diagnose .card .card-header {
  color: #fff;
  padding: 10px 30px;
  border-radius: 4px 4px 0px 0px;
  background-color: #0051ff;
}
.FormUpload .doctor-diagnose .card .card-body {
  padding: 10px 30px;
  border-radius: 0px 0px 4px 4px;
}
.FormUpload .doctor-diagnose .custom-card {
  border: 1px solid #e7e7e7;
}
.FormUpload .doctor-diagnose .custom-card .card-header {
  color: #707070;
  padding: 10px 30px;
  border-radius: 4px 4px 0px 0px;
  background-color: #fff;
  border-bottom: 1px solid #e7e7e7;
}
.FormUpload * > * .ant-tabs-card .ant-tabs-nav {
  margin: 0;
}
.FormUpload * > * .ant-tabs-card .ant-tabs-content {
  border-radius: 0px 4px 4px 4px;
  background-color: #f1f5ff;
}
.FormUpload * > * .ant-tabs-card .ant-tabs-tab {
  padding: 10px 28px;
  border: 1px solid #abbfe9;
  border-bottom: none;
  border-radius: 6px 6px 0px 0px !important;
  background-color: #fff;
  color: #0051ff;
  margin-right: 4px;
}
.FormUpload * > * .ant-tabs-card .ant-tabs-tab.ant-tabs-tab-active {
  border: 1px solid #abbfe9;
  border-bottom: none;
  background-color: #0051ff;
}
.FormUpload * > * .ant-tabs-card .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
  font-weight: 200;
}
@media (min-width: 1600px) {
  .ImageUpload .ant-upload-list-picture-card,
  .ImageUpload .ant-upload-select-picture-card,
  .ImageUpload .ant-upload-list-picture-card-container {
    width: 200px;
    height: 200px;
  }
  .ant-form-item-explain-connected {
    padding-top: 10px;
  }
}
