@import "../theme//ABus/font/sukhumvit-set/stylesheet.css";
@import "../theme/ABus/less/variables.less";

html {
  font-size: @font-size-base;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 80%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.absolute {
  position: absolute;
}

.color-primary {
  color: @primary-color;
}

.background-primary {
  background: @primary-color;
}

.background-tag {
  background: #FF748E;
}

.text-underline {
  text-decoration: underline !important;
}

//padding
.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 1.5rem !important;
}

///
.mt-05 {
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-15 {
  margin-top: 1.5rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-41 {
  margin-top: 4.1rem;
}

.m-0 {
  margin: 0px !important;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-01 {
  margin-top: 1px;
}
