/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.FormPatient {
  height: 100%;
  max-height: 64vh;
  overflow-y: scroll;
}
.FormPatient .box {
  border: 1px solid rgba(221, 221, 221, 0.493);
  padding: 40px 20px;
  min-height: 500px;
  margin-bottom: 20px;
}
.FormPatient-create {
  height: 100%;
  max-height: 72vh;
  overflow-y: scroll;
}
.FormPatient-create .box {
  border: 1px solid rgba(221, 221, 221, 0.493);
  padding: 40px 20px;
  min-height: 500px;
  margin-bottom: 20px;
}
.FormPatient::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.FormPatient-create::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
/* Add a thumb */
@media (min-width: 1200px) {
  .FormPatient {
    max-height: 59vh;
  }
}
@media (min-width: 1600px) {
  .FormPatient {
    max-height: 62vh;
  }
}
