/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.FormVisit .box {
  border: 1px solid rgba(221, 221, 221, 0.493);
  padding: 40px 20px;
  min-height: 500px;
  margin-bottom: 20px;
}
.FormVisit label {
  color: #6c6c6c;
}
.modalMap {
  background-color: "white";
  padding: "20px";
  height: "500px";
  margin-top: 20px;
}
.longdo-map {
  min-height: 100%;
  min-width: 100%;
}
.result-map {
  background-color: white;
  display: block;
  z-index: 10;
  flex-direction: column;
  padding: 4px;
  min-width: 100%;
  overflow-y: scroll;
}
.result-map::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.box-map {
  border: 1px solid rgba(221, 221, 221, 0.493);
  padding: 20px 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  min-height: 500px;
}
@media (min-width: 768px) {
  .longdoMap-height {
    min-height: 100%;
  }
  .height-resultMap {
    height: 450px;
  }
  .drawer-create-visit .ant-drawer-content-wrapper {
    width: 740px !important;
  }
}
@media (min-width: 992px) {
  .longdoMap-height {
    min-height: 100%;
  }
  .height-resultMap {
    height: 450px;
  }
}
@media (min-width: 1200px) {
  .FormPatient {
    max-height: 69vh;
  }
}
@media (min-width: 1600px) {
  .FormPatient {
    max-height: 75vh;
  }
}
