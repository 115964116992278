.FormUpload {
  overflow-y: auto;
  min-height: 100%;

  .doctor-diagnose {
    padding: 20px;

    .container-box {
      background-color: #f1f5ff;
      border: 1px solid #abbfe9;
      margin: 0 !important;
    }

    .tabs-content {
      padding: 20px 10px
    }

    .box {
      border-radius: 4px;
      padding: 30px 20px
    }

    .card {
      background-color: #fff;
      min-height: 200px;
      border-radius: 4px;

      .card-header {
        color: #fff;
        padding: 10px 30px;
        border-radius: 4px 4px 0px 0px;
        background-color: #0051ff;
      }

      .card-body {
        padding: 10px 30px;
        border-radius: 0px 0px 4px 4px;
      }
    }

    .custom-card {
      border: 1px solid #e7e7e7;

      .card-header {
        color: #707070;
        padding: 10px 30px;
        border-radius: 4px 4px 0px 0px;
        background-color: #fff;
        border-bottom: 1px solid #e7e7e7;
      }
    }
  }

  *>* {
    .ant-tabs-card {
      .ant-tabs-nav {
        margin: 0
      }

      .ant-tabs-content {
        // border: 1px solid #abbfe9;
        border-radius: 0px 4px 4px 4px;
        background-color: #f1f5ff;
      }

      .ant-tabs-tab {
        padding: 10px 28px;
        border: 1px solid #abbfe9;
        border-bottom: none;
        border-radius: 6px 6px 0px 0px !important;
        background-color: #fff;
        color: #0051ff;
        margin-right: 4px;
      }

      .ant-tabs-tab.ant-tabs-tab-active {
        border: 1px solid #abbfe9;
        border-bottom: none;
        background-color: #0051ff;

      }

      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #fff;
          font-weight: 200
        }
      }
    }
  }
}