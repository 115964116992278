/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.hotspot .button-wrap {
  display: table;
}
.hotspot .pulse-button {
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  display: table-cell;
  width: 30px;
  height: 30px;
  font-size: 0.9em;
  font-weight: bold;
  color: #fff;
  border: none;
  box-shadow: 0 0 0 0 #1C5AE8;
  border-radius: 100%;
  vertical-align: middle;
  background: #1C5AE8;
  cursor: pointer;
  -webkit-animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
}
.hotspot .pulse-button:hover {
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
  padding-top: 5px;
}
.hotspot .pulse-button.deactivate {
  color: #000;
  background: #999;
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}
@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}
