/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.login-container {
  height: 100vh;
  padding: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../../assets//images/background-login.svg");
}
.welcome-container {
  margin-top: 40px;
  margin-bottom: 40px;
}
.welcome-subTitle {
  font-size: 1rem;
  color: #8b929d;
}
.copyright {
  font-size: 10px;
  color: #8b929d;
}
#components-form-normal-login {
  height: 100vh;
  padding: 5px;
  max-width: 550px !important;
  align-items: center;
  display: flex;
}
#components-form-normal-login .login-form {
  width: 100%;
}
#components-form-normal-login .login-form-button {
  width: 100%;
}
#components-form-normal-login label {
  color: #8b929d;
}
#components-form-normal-login .ant-form-item {
  margin-bottom: 24px;
}
@media (min-width: 480px) {
  .login-container {
    background-image: none;
  }
  .welcome-subTitle {
    font-size: 1rem;
  }
  .copyright {
    font-size: 10px;
  }
  #components-form-normal-login .ant-form-item {
    margin-bottom: 32px;
  }
}
@media (min-width: 576px) {
  .login-container {
    background-image: none;
  }
  .welcome-subTitle {
    font-size: 1rem;
  }
  .copyright {
    font-size: 10px;
  }
  #components-form-normal-login {
    height: unset;
    padding: 5px;
    max-width: 550px !important;
    align-items: center;
    display: flex;
  }
  #components-form-normal-login .ant-form-item {
    margin-bottom: 32px;
  }
}
@media (min-width: 768px) {
  .login-container {
    height: 100vh;
    padding: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../../assets//images/background-login.svg");
  }
  .welcome-subTitle {
    font-size: 1rem;
  }
  #components-form-normal-login {
    padding: 30px;
  }
  #components-form-normal-login .ant-form-item {
    margin-bottom: 32px;
  }
}
@media (min-width: 992px) {
  .welcome-subTitle {
    font-size: 1rem;
  }
  #components-form-normal-login {
    padding: 40px;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1600px) {
  .welcome-container {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .welcome-subTitle {
    font-size: 1rem;
  }
  #components-form-normal-login {
    padding: 40px;
  }
  #components-form-normal-login .ant-form-item {
    margin-bottom: 35px;
  }
}
