/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
#defaultLayout-layout-custom-trigger {
  min-height: 100vh;
}
#defaultLayout-layout-custom-trigger .logo {
  max-width: 300px;
}
