/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.action-button {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 2;
  transition: visibility 0s, opacity 0.1s linear;
  background: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-wrapper {
  cursor: pointer;
}
.image-wrapper:hover #delete-mask {
  visibility: visible;
  opacity: 50;
}
.size-image {
  color: white;
  font-size: 18px;
  padding-left: 10px;
}
@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}
#dicom-canvas .cornerstone-canvas {
  width: 100% !important;
}
