.FormPatient {
  height: 100%;
  max-height: 64vh;
  overflow-y: scroll;
  .box {
    border: 1px solid rgba(221, 221, 221, 0.493);
    padding: 40px 20px;
    min-height: 500px;
    margin-bottom: 20px;
  }
}

.FormPatient-create {
  height: 100%;
  max-height: 72vh;
  overflow-y: scroll;
  .box {
    border: 1px solid rgba(221, 221, 221, 0.493);
    padding: 40px 20px;
    min-height: 500px;
    margin-bottom: 20px;
  }
}

.FormPatient::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  // background-color: #aaa; /* or add it to the track */
}

.FormPatient-create::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  // background-color: #aaa; /* or add it to the track */
}

/* Add a thumb */
// .FormPatient::-webkit-scrollbar-thumb {
//   // background: #000;
//   // display: none;
// }
