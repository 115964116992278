@media (min-width: @screen-xxl-min) {
  // .login-container {
  // }
  .welcome-container {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .welcome-subTitle {
    font-size: 1rem;
  }
  // .copyright {
  // }
  #components-form-normal-login {
    padding: 40px;
  }

  // #components-form-normal-login .login-form {
  // }
  // #components-form-normal-login .login-form-button {
  // }
  // #components-form-normal-login label {
  // }

  #components-form-normal-login .ant-form-item {
    margin-bottom: 35px;
  }
}
