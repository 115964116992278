@media (min-width: @screen-xxl-min) {
  .ImageUpload {
    .ant-upload-list-picture-card,
    .ant-upload-select-picture-card,
    .ant-upload-list-picture-card-container {
      width: 200px;
      height: 200px;
    }
  }
  .ant-form-item-explain-connected {
    padding-top: 10px;
  }
}
