/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
#ABUS-layout-custom-trigger {
  min-height: 100vh;
}
#ABUS-layout-custom-trigger .trigger {
  cursor: pointer;
  font-size: 18px;
  transition: color 0.3s;
  line-height: 64px;
}
#ABUS-layout-custom-trigger .trigger:hover {
  color: #1C5AE8;
}
#ABUS-layout-custom-trigger .logo {
  height: 80px;
  padding: 15px 30px;
  max-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#ABUS-layout-custom-trigger .site-layout-menu-background {
  -moz-box-shadow: 2px 2px 15px -8px rgba(189, 189, 189);
  -webkit-box-shadow: 2px 2px 15px -8px rgba(189, 189, 189);
  background-color: #fff;
  background-image: url("../../../assets/images/bg-dashboard.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 280px;
  box-shadow: 2px 2px 15px -8px #bdbdbd;
}
#ABUS-layout-custom-trigger .site-layout-menu {
  padding: 20px 30px;
  background: transparent;
}
#ABUS-layout-custom-trigger .site-layout-menu .ant-menu-item {
  margin: 5px 0px;
  color: #707070;
  height: 48px;
  font-size: 1.1rem;
  background: rgba(255, 255, 255, 0.7);
}
#ABUS-layout-custom-trigger .site-layout-menu .ant-menu-item .ant-menu-item-icon {
  font-size: 1.5rem;
}
#ABUS-layout-custom-trigger .site-layout-menu .ant-menu-item .ant-menu-item-icon + span {
  margin-left: 20px;
}
#ABUS-layout-custom-trigger .site-layout-menu .ant-menu-item-selected {
  color: #fff;
  background-color: #1C5AE8;
}
#ABUS-layout-custom-trigger .site-layout-menu .ant-menu-item-active {
  color: #fff;
  background-color: #1C5AE8;
}
#ABUS-layout-custom-trigger .site-layout .site-layout-background {
  background: #fff;
}
#ABUS-layout-custom-trigger .header {
  padding: 0 24px;
  height: 80px;
  max-height: 80px;
}
#ABUS-layout-custom-trigger .header .dropdown-profile {
  cursor: pointer;
}
#ABUS-layout-custom-trigger .header .dropdown-profile .profile {
  line-height: 20px;
  min-width: 130px;
}
#ABUS-layout-custom-trigger .header .dropdown-profile .profile .role {
  color: #8b929d;
  font-size: 0.8rem;
}
#ABUS-layout-custom-trigger .ant-layout-header {
  line-height: 80px;
}
#ABUS-layout-custom-trigger .site-layout .site-layout-content {
  padding: 24px;
}
#ABUS-layout-custom-trigger .ant-layout-sider-collapsed .logo {
  padding: 10px 15px;
}
#ABUS-layout-custom-trigger .ant-layout-sider-collapsed .site-layout-menu {
  padding: 10px 15px;
}
#ABUS-layout-custom-trigger .ant-layout-sider-collapsed .site-layout-menu .ant-menu-item {
  height: 49px;
  padding: calc((49px - 1.5rem) / 2) calc((49px - 1.5rem) / 2);
}
@media (min-width: 768px) {
  .header-Collap {
    width: 92vw;
  }
  .header-NoCollap {
    width: 68vw;
  }
}
@media (min-width: 992px) {
  .header-Collap {
    width: 96vw;
  }
  .header-NoCollap {
    width: 85.5vw;
  }
}
