.btn.btn-secondary {
  background: @secondary-color !important;

  * {
    color: #fff;
  }
}

.btn.btn-secondary.ant-btn:hover,
.btn.btn-secondary.ant-btn:focus {
  border-color: @secondary-color;
  opacity: 0.8;
}

.ant-btn-ghost {
  color: #777777;
  border-color: #F0F2F5;
  background: transparent;
}

.abus-table {
  .ant-table-content {
    background: #fff;
    padding-bottom: 15px;
    color: @dark-gray-color;
  }

  .ant-table.ant-table-small {

    .ant-table-thead>tr>th,
    .ant-table-cell {
      padding: 8px 8px 8px 16px;
    }
  }

  .ant-table-thead {
    background-color: #475f7b;

    .ant-table-cell {
      color: #fff;
      background: unset;
      height: 59px;
    }
  }

  .ant-table-tbody>tr>td {
    border-bottom: unset;
  }

  .deep-gray {
    background: @deep-gray;
  }

  .shallow-gray {
    background: @shallow-gray;
  }
}

.abus-pagination {
  li {
    background: transparent;
    color: @dark-gray-color;

    *>*,
    * {
      background: transparent;
      background-color: transparent !important;
      color: @dark-gray-color;
    }
  }

  .ant-pagination-item-active {
    background: @primary-color;

    a {
      color: #fff;
    }
  }
}

.btn-action {
  padding: 20px 10px;
  font-size: 1.5rem;
}

.ant-dropdown-menu-item:hover {
  background-color: #ff748e26;
}

.ant-dropdown-menu-item {
  padding: 10px 20px;
}

.ant-col.ant-form-item-label {
  label {
    height: 24px !important;
    font-size: 0.95rem;
  }
}

.ant-radio-inner {
  width: 32px;
  height: 32px;
}

.ant-radio-inner::after {
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
}

// .ant-radio-wrapper {
// align-items: center;
// }
.ant-radio-wrapper.content-radio {
  margin-right: 28px ;
:is(.ant-radio-inner) {
      width: 20px;
      height: 20px;
    }
:is(.ant-radio-inner)::after {
      width: 20px;
      height: 20px;
      margin: -1px -1px auto;
    }
}

.btn-update {
  background: #475f7b;
  color: #fff;
}

.btn-update:hover {
  color: #fff;
  background: #5d6f83;
  border-color: #475f7b;
}

.ant-tabs-card.ant-tabs-top.tabDoctor>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active {
  height: 60px;
  background: @primary-color;

}

.ant-tabs-card.tabDoctor>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab {
  background: white;
}


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .font-lenght {
  color: @primary-color;
  background-color: white !important;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-tab .ant-tabs-tab-btn .font-lenght {
  color: white;
  background-color: @primary-color;
  text-shadow: 0 0 0.25px currentcolor;
}

// .ant-tabs-card.ant-tabs-top.tabDoctor > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active{
//   // color:white !important;
// }

.Select-role.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px;
}

.Select-role.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 48px !important;
}

.font-tabs {
  font-size: 12pt;
}

.tabDoctor.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  margin-left: -1px;
}

.tabDoctor.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
  margin-left: 0px !important;
}



.label-date .ant-form-item-label {
  display: flex;
  align-items: center;
}

.radio-doctor .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  margin-top: -10px;
}



.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px;
}

.drawer-create-visit .ant-drawer-content-wrapper {
  width: 850px !important;
}

.ant-image-preview-operations {
  color: #ff748e !important;
}

.ant-image-preview-wrap {
  background-color: #0000009c !important;
}

.ant-progress-text {
  color: #1C5AE8
}