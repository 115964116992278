.hotspot {
  .button-wrap {
    display: table;
  }

  .pulse-button {
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    display: table-cell;
    width: 30px;
    height: 30px;
    font-size: 0.9em;
    font-weight: bold;
    color: #fff;
    border: none;
    box-shadow: 0 0 0 0 @primary-color;
    border-radius: 100%;
    vertical-align: middle;
    background: @primary-color;
    cursor: pointer;
    -webkit-animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);

    &:hover {
      -webkit-animation: none;
      -moz-animation: none;
      animation: none;
      padding-top: 5px;
    }

    &.deactivate {
      color: #000;
      background: #999;
      -webkit-animation: none;
      -moz-animation: none;
      animation: none;
    }
  }
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}
