.login-container {
  height: 100vh;
  padding: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  background-image: url("../../../../assets//images/background-login.svg");
}

.welcome-container {
  margin-top: 40px;
  margin-bottom: 40px;
}

.welcome-subTitle {
  font-size: 1rem;
  color: #8b929d;
}

.copyright {
  font-size: 10px;
  color: #8b929d;
}

// .logo > * {
//   width: 90% !important;
// }
#components-form-normal-login {
  height: 100vh;
  padding: 5px;
  max-width: 550px !important;
  align-items: center;
  display: flex;
}

#components-form-normal-login .login-form {
  width: 100%;
}

#components-form-normal-login .login-form-button {
  width: 100%;
}

#components-form-normal-login label {
  color: #8b929d;
}

#components-form-normal-login .ant-form-item {
  margin-bottom: 24px;
}