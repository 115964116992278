@media (min-width: @screen-md-min) {
    .header-Collap{
        width: 92vw;
        // transition:  1s;
    }

    .header-NoCollap{
        width: 68vw;
        // transition:  1s;
    }
}
